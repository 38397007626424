import { fromJS } from 'immutable';
import { map } from 'config';
import geoViewport from '@mapbox/geo-viewport';
import * as commuteOfferActions from 'modules/commuteOffer/actions';
import debug from 'utils/debug';
import * as actions from './actions';

const D2 = debug('m:maps:index');

export const initialState = fromJS({
  lastProcessedDataHash: null,
  commuteOffer: {},
  dataset: {},
  geofences: {},
  newGeofence: {},
  simulation: {},
})
  .setIn(['commuteOffer', 'viewport'], map.initialViewState)
  .setIn(['dataset', 'viewport'], map.initialViewStateDataset)
  .setIn(['geofences', 'viewport'], map.initialViewState)
  .setIn(['newGeofence', 'viewport'], map.initialViewState)
  .setIn(['simulation', 'viewport'], map.initialViewState);

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case commuteOfferActions.COMMUTE_OFFER_FETCH_RESULTS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { error } = payload;
        if (error) {
          return state;
        }

        const { result } = payload;

        const lastProcessedDataHash = state.get('lastProcessedDataHash');

        if (result.loadedHash === lastProcessedDataHash) {
          return state;
        }

        const { projectRecord, commuteOffer } = result;

        if (!projectRecord) {
          return state;
        }

        const project = projectRecord.toJS();

        const ordersDisplayMode = commuteOffer.$source?.simulation?.data?.logistics_api_settings?.orders_display || 'pickup_and_dropoff';
        $D2.S.INFO('ordersDisplayMode', { ordersDisplayMode });

        const ordersDisplayFilter = ((mode) => {
          if (mode === 'pickup_only') return node => node.node_type === 'pickup';
          if (mode === 'dropoff_only') return node => node.node_type === 'dropoff';
          return () => true;
        })(ordersDisplayMode);

        const validNodes = commuteOffer.stateless_api_request_data.nodes
          .filter(node => node.lat && node.lon);

        const visibleNodes = validNodes.filter(ordersDisplayFilter);

        $D2.S.INFO('visibleNodes', { visibleNodes, validNodes });

        const bbox = visibleNodes
          .reduce(
            (memo, node) =>
              $D2.S.V(
                'nodes.reduce',
                { node, memo },
                {
                  lon: {
                    min: !memo.lon.min
                      ? node.lon - 0.001
                      : Math.min(memo.lon.min, node.lon - 0.001),
                    max: !memo.lon.max
                      ? node.lon + 0.001
                      : Math.max(memo.lon.max, node.lon + 0.001),
                  },
                  lat: {
                    min: !memo.lat.min
                      ? node.lat - 0.001
                      : Math.min(memo.lat.min, node.lat - 0.001),
                    max: !memo.lat.max
                      ? node.lat + 0.001
                      : Math.max(memo.lat.max, node.lat + 0.001),
                  },
                }
              ),
            { lon: { min: null, max: null }, lat: { min: null, max: null } }
          );

        const { center, zoom } = geoViewport.viewport(
          [bbox.lon.min, bbox.lat.min, bbox.lon.max, bbox.lat.max],
          [300, 200],
          0, // minZoom
          13 // maxZoom
        );

        $D2.S.INFO('center', { center, zoom });

        const { lon, lat } = project;

        const viewport = {
          longitude: center[0] || lon || map.initialViewState.longitude,
          latitude: center[1] || lat || map.initialViewState.latitude,
          // center: [
          //   center[0] || lon || map.initialViewState.longitude,
          //   center[1] || lat || map.initialViewState.latitude,
          // ],
          zoom,
          pitch: 0,
          bearing: 0,
        };
        
        // don't set ['geofences', 'viewport'] if it was set once
        // if it was done before, skip it, to prevent "Map zooms out after some time by itself" bug
        // https://swatmobile.atlassian.net/browse/SP-2943
        if (lastProcessedDataHash) {
          $D2.S.INFO('viewport', { viewport, bbox, lon, lat, project, commuteOffer, lastProcessedDataHash });
          return state
            .set('lastProcessedDataHash', result.loadedHash)
            .setIn(['geofences', 'viewport'], viewport);
        }

        $D2.S.INFO('viewport', { viewport, bbox, lon, lat, project, commuteOffer, lastProcessedDataHash });
        return state
          .set('lastProcessedDataHash', result.loadedHash)
          .setIn(['commuteOffer', 'viewport'], viewport)
          .setIn(['simulation', 'viewport'], viewport)
          .setIn(['geofences', 'viewport'], viewport);
      });
    }
    case actions.CHANGE_VIEWPORT_COMMUTE_OFFER: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      D2.S.INFO('CHANGE_VIEWPORT_COMMUTE_OFFER::viewport', { viewport });
      return state.setIn(['commuteOffer', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_SIMULATION: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      D2.S.INFO('CHANGE_VIEWPORT_SIMULATION::viewport', { viewport });
      return state.setIn(['simulation', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_DATASET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      D2.S.INFO('CHANGE_VIEWPORT_DATASET::viewport', { viewport });
      return state.setIn(['dataset', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_GEOFENCES: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      D2.S.INFO('CHANGE_VIEWPORT_GEOFENCES::viewport', { viewport });
      return state.setIn(['geofences', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_NEW_GEOFENCE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      D2.S.INFO('CHANGE_VIEWPORT_NEW_GEOFENCE::viewport', { viewport });
      return state.setIn(['newGeofence', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_ALL_MAPS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { latitude, longitude } = payload;
      D2.S.INFO('CHANGE_VIEWPORT_ALL_MAPS::viewport', { viewport });
      return state
        .setIn(['commuteOffer', 'viewport', 'latitude'], latitude)
        .setIn(['commuteOffer', 'viewport', 'longitude'], longitude)
        .setIn(['dataset', 'viewport', 'latitude'], latitude)
        .setIn(['dataset', 'viewport', 'longitude'], longitude)
        .setIn(['simulation', 'viewport', 'latitude'], latitude)
        .setIn(['simulation', 'viewport', 'longitude'], longitude)
        .setIn(['geofences', 'viewport', 'latitude'], latitude)
        .setIn(['geofences', 'viewport', 'longitude'], longitude)
        .setIn(['newGeofence', 'viewport', 'latitude'], latitude)
        .setIn(['newGeofence', 'viewport', 'longitude'], longitude);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
