import { takeLatest, put, take, select } from 'redux-saga/effects';
import { getViewportFromGeometry } from 'utils/maps';

// import { diffString } from 'json-diff';

import debug from 'utils/debug';
import {
  // uiStateSelector,
  editableBookingIdSelector,
  activeVehicleIdsSelector,
  addStopModeSelector,
} from 'modules/ui/selectors';
import {
  nodesByBookingIdSelector,
  commuteOfferCurrentDataSelector,
} from 'modules/commuteOffer/selectors';
import * as actions from './actions';
import * as commuteOfferActions from '../commuteOffer/actions';
import * as uiActions from '../ui/actions';

import {
  routeSourceSelector,
  vehiclesStopsSelector,
  bookingsSourceSelector,
  commuteOfferViewportSelector,
  simulationViewportSelector,
} from './selectors';

const D2 = debug('m:maps:saga');

// const saveState = state => state.toJS();

// const createHandler = fn => {
//   return function* vehiclesLayerClickHandler(opts) {
//     const uiState1 = saveState(yield select(uiStateSelector));

//     yield* fn(opts);

//     const uiState2 = saveState(yield select(uiStateSelector));
//     console.log(diffString(uiState1, uiState2));
//   };
// };

function* flyToCommuteOfferVehicle({ payload }) {
  D2.S.DEBUG('flyToCommuteOfferVehicle()');
  D2.S.TRACE($ => $('Payload:', payload));

  const id = payload;
  const routes = yield select(routeSourceSelector);

  if (routes.features.length < 2) {
    return;
  }

  const currentRoute = routes.features.find(
    item => item.properties.agent_id === id
  );
  const viewport = getViewportFromGeometry(currentRoute);
  D2.S.INFO('changeViewportCommuteOffer', viewport);
  yield put(actions.changeViewportCommuteOffer(viewport));
}

function* flyToCommuteOfferPoint({ payload }) {
  D2.S.DEBUG('flyToCommuteOfferPoint()');
  D2.S.TRACE($ => $('Payload:', payload));

  const { lon, lat } = payload;

  const viewport = yield select(commuteOfferViewportSelector);

  yield put(
    actions.changeViewportSimulation({
      ...viewport,
      latitude: lat,
      longitude: lon,
      zoom: viewport.zoom < 13 ? 13 : viewport.zoom,
    })
  );
}

function* flyToCommuteOfferActiveStop({ payload }) {
  D2.S.INFO('flyToCommuteOfferActiveStop:Request', { payload });

  try {
    const uid = payload;

    const vehicleStops = yield select(vehiclesStopsSelector);
    D2.S.INFO('flyToCommuteOfferActiveStop:vehicleStops', { vehicleStops });

    // const activeVehicleIds = yield select(activeVehicleIdsSelector);
    // D2.S.INFO('flyToCommuteOfferActiveStop:activeVehicleIds', {
    //   activeVehicleIds
    // });

    const currentStop = vehicleStops.find(
      item => item.uid === uid // && activeVehicleIds.includes(item.agent_id)
    );
    D2.S.INFO('flyToCommuteOfferActiveStop:currentStop', {
      currentStop,
      vehicleStops,
      uid,
    });

    const viewport = yield select(commuteOfferViewportSelector);

    if (currentStop && currentStop.lat && currentStop.lon) {
      D2.S.INFO('changeViewportCommuteOffer', viewport);
      yield put(
        actions.changeViewportCommuteOffer({
          ...viewport,
          latitude: currentStop.lat,
          longitude: currentStop.lon,
          zoom: viewport.zoom < 13 ? 13 : viewport.zoom,
        })
      );
    }
    D2.S.INFO('flyToCommuteOfferActiveStop:Success', { payload });
  } catch (error) {
    D2.S.INFO('flyToCommuteOfferActiveStop:Failure', { error, payload });
    throw error;
  }
}

function* flyToCommuteOfferActiveBooking({ payload }) {
  D2.S.DEBUG('flyToCommuteOfferActiveBooking()');
  D2.S.TRACE($ => $('Payload:', payload));

  const id = payload;
  const bookings = yield select(bookingsSourceSelector);
  const viewport = yield select(commuteOfferViewportSelector);

  D2.S.DEBUG('Bookings:', bookings);

  const currentBooking = bookings.features.find(
    item => item.properties.uid === id
  );

  if (currentBooking) {
    D2.S.INFO('changeViewportCommuteOffer', viewport);
    yield put(
      actions.changeViewportCommuteOffer({
        ...viewport,
        latitude: currentBooking.geometry.coordinates[1],
        longitude: currentBooking.geometry.coordinates[0],
        zoom: viewport.zoom < 13 ? 13 : viewport.zoom,
      })
    );
  }
}

function* flyToSimulationVehicle({ payload }) {
  D2.S.DEBUG('flyToSimulationVehicle()');
  D2.S.TRACE($ => $('Payload:', payload));

  const id = payload;
  const routes = yield select(routeSourceSelector);

  if (routes.features.length < 2) {
    return;
  }

  const currentRoute = routes.features.find(
    item => item.properties.agent_id === id
  );
  const viewport = getViewportFromGeometry(currentRoute);
  yield put(actions.changeViewportSimulation(viewport));
}

function* flyToSimulationActiveStop({ payload }) {
  D2.S.DEBUG('flyToSimulationActiveStop()');
  D2.S.TRACE($ => $('Payload:', payload));

  const id = payload;

  const vehicleStops = yield select(vehiclesStopsSelector);
  D2.S.INFO('vehicleStops', { vehicleStops });

  const activeVehicleIds = yield select(activeVehicleIdsSelector);

  const currentStop = vehicleStops.find(
    item => item.id === id && activeVehicleIds.includes(item.agent_id)
  );

  const viewport = yield select(simulationViewportSelector);

  if (currentStop) {
    yield put(
      actions.changeViewportSimulation({
        ...viewport,
        latitude: currentStop.lat,
        longitude: currentStop.lon,
        zoom: viewport.zoom < 13 ? 13 : viewport.zoom,
      })
    );
  } else {
    D2.S.DEBUG('Error:', 'There is no current stop');
  }
}

function* flyToSimulationActiveBooking({ payload }) {
  D2.S.INFO('flyToSimulationActiveBooking()', { payload });

  const id = payload;
  const bookings = yield select(bookingsSourceSelector);
  const viewport = yield select(simulationViewportSelector);

  D2.S.DEBUG('Bookings:', bookings);

  const currentBooking = bookings.features.find(
    item => item.properties.uid === id
  );

  if (currentBooking) {
    yield put(
      actions.changeViewportSimulation({
        ...viewport,
        latitude: currentBooking.geometry.coordinates[1],
        longitude: currentBooking.geometry.coordinates[0],
        zoom: viewport.zoom < 13 ? 13 : viewport.zoom,
      })
    );
  } else {
    D2.S.DEBUG('Error:', 'There is no current booking');
  }
}

function* stopsClickLayer({ payload }) {
  D2.S.INFO('stopsClickLayer:Request', { payload });

  const { event, feature } = payload;

  const addStopMode = yield select(addStopModeSelector);
  D2.S.INFO('stopsClickLayer:addStopMode', { addStopMode });

  const activeVehicleIds = yield select(activeVehicleIdsSelector);
  D2.S.INFO('stopsClickLayer:activeVehicleIds', { activeVehicleIds });

  if (addStopMode) {
    if (activeVehicleIds.size === 1) {
      const { code } = feature.properties;
      yield put(
        commuteOfferActions.addStopToRoute(code, activeVehicleIds.get(0))
      );
    } else {
      const { name } = feature.properties;
      yield put(uiActions.setStopSearchQuery(name));
    }
    return;
  }

  if (!activeVehicleIds.size) {
    return;
  }

  const nodesByBookingId = yield select(nodesByBookingIdSelector);

  const editableBookingId = yield select(editableBookingIdSelector);
  D2.S.INFO('stopsClickLayer:editableBookingId', { editableBookingId });

  if (!editableBookingId) {
    return;
  }

  const nodeType = event.originalEvent.shiftKey ? 'dropoff' : 'pickup';

  const stop = feature;

  const nodes = nodesByBookingId[editableBookingId];
  D2.S.INFO('stopsClickLayer:nodes', { nodes });

  const node = nodes[0];
  const { properties } = stop;
  const { coordinates } = stop.geometry;

  const currentOffer = yield select(commuteOfferCurrentDataSelector);

  const booking = currentOffer.result.assigned_bookings.find(
    assignedBooking => assignedBooking.uid === editableBookingId
  );
  D2.S.INFO('stopsClickLayer:booking', { booking });

  if (!booking) {
    return;
  }

  const vehicleId = booking.assigned_vehicle_id;

  const newNode = {
    ...node,
    uid: Math.random(),
    stop_id: String(properties.id),
    location_code: properties.code,
    location_name: `${properties.name} #${properties.code}`,
    lat: coordinates[1],
    lon: coordinates[0],
    node_type: nodeType,
    $agent_id: vehicleId,
  };
  D2.S.INFO('stopsClickLayer:newNode', { newNode });

  if (editableBookingId) {
    yield put(commuteOfferActions.addNode([newNode]));
    yield put(commuteOfferActions.setBookingNode([newNode]));

    yield put(commuteOfferActions.fetchRoute({ agent_id: vehicleId }));
    yield take(commuteOfferActions.ROUTE_FETCH_RESULTS);

    yield put(commuteOfferActions.recalculateVehicleTime([vehicleId], 'set'));
  }

  if (activeVehicleIds.size && !editableBookingId) {
    yield put(commuteOfferActions.changeRouteStop(stop));
  }
}

function* Saga() {
  yield takeLatest(actions.STOPS_LAYER_CLICK, stopsClickLayer);
  yield takeLatest(
    actions.FLY_TO_COMMUTE_OFFER_VEHICLE,
    flyToCommuteOfferVehicle
  );
  yield takeLatest(actions.FLY_TO_COMMUTE_OFFER_POINT, flyToCommuteOfferPoint);
  yield takeLatest(
    actions.FLY_TO_COMMUTE_OFFER_ACTIVE_STOP,
    flyToCommuteOfferActiveStop
  );
  yield takeLatest(
    actions.FLY_TO_COMMUTE_OFFER_ACTIVE_BOOKING,
    flyToCommuteOfferActiveBooking
  );
  yield takeLatest(actions.FLY_TO_SIMULATION_VEHICLE, flyToSimulationVehicle);
  yield takeLatest(
    actions.FLY_TO_SIMULATION_ACTIVE_STOP,
    flyToSimulationActiveStop
  );
  yield takeLatest(
    actions.FLY_TO_SIMULATION_ACTIVE_BOOKING,
    flyToSimulationActiveBooking
  );
}

export default Saga;
